<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <!-- popup -->
    <div class="popup" id="popup-mypage-address">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <!-- popup-inner > popup-header -->
        <header class="popup-header">
          <h3 class="title">우편물 수령주소 변경</h3>
        </header>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <!-- kb-form-fields -->
          <div class="kb-form-fields">
            <!-- kb-form-row:현재주소 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">현재주소</span>
                </label>
              </div>
              <div class="kb-form-column">
                <p v-if="userAddrInfo.shipInfoSn > 0" class="kb-form-text">{{`(${userAddrInfo.shipZip}) ${userAddrInfo.shipAddr} ${userAddrInfo.shipDtlAddr}`}}</p>
                <p v-else class="kb-form-text">-</p>
              </div>
<!--                <div class="kb-form-column">
                <p class="kb-form-text">12830 | 서울 영등포구 의사당대로 | 3층 304호</p>
              </div>-->
            </div>
            <!-- kb-form-row:검색할 주소검색 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">변경할 주소검색</span>
                </label>
              </div>
              <div class="kb-form-column">
                <!-- kb-form-address -->
                <div class="kb-form-address">
                  <input v-model.trim="search.keyword" type="text" class="kb-form-control" placeholder="도로명주소, 건물명 또는 지번을 입력해주세요." @keyup.enter.prevent="clickSearch">
                  <button class="kb-btn-search" @click="clickSearch"><i class="icon-search"></i></button>
                </div>
                <!-- //kb-form-address -->
              </div>
            </div>

            <template v-if="isSelected">
              <!-- kb-form-row:변경할 주소검색 -->
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">변경할 기본 주소</span>
                  </label>
                </div>
                <div class="kb-form-column">
                  <p class="kb-form-text">{{ params.shipZip }} | {{ params.shipAddr }}</p>
                </div>
              </div>
              <!-- kb-form-row:변경할 상세 주소 -->
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">변경할 상세 주소</span>
                  </label>
                </div>
                <div class="kb-form-column">
                  <input v-model="params.shipDtlAddr" type="text" class="kb-form-control" placeholder="상세주소를 입력하세요.">
                </div>
              </div>
            </template>

            <div v-if="isSearch" class="kb-form-row">
              <!-- address-list-container -->
              <div class="address-list-container is-active">
                <ul class="address-list">
                  <li v-for="(item ,idx) in bodies" class="address-item" :key="`${idx}`">
                    <div class="kb-form-check">
                      <label :for="`chk_${idx}`" class="kb-form-check-label">
                        <span class="text">{{ item.zipNo }}</span>
                        <strong class="text">{{ item.customAddr }}</strong>
                      </label>
                      <input v-model="addrInfo" :value="item" type="radio" class="kb-form-check-input" name="chk_zipcode" :id="`chk_${idx}`" @click.stop="selectedAddress(idx)">
                    </div>
                  </li>
                </ul>
              </div>
              <!-- //address-list-container -->
            </div>

          </div>
        </div>
        <!-- popup-inner > popup-buttons -->
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSave"><span class="text">변경</span></button>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>
import {myCommonAddressSetup} from "@/assets/js/modules/mypage/profile/my-common-address-setup";


export default {
  name: "MyCommonAddress",
  props: {
    modelValue: Boolean,
    userAddrInfo: Object,
  },
  emits: ['update:modelValue'],
  setup: myCommonAddressSetup
}
</script>