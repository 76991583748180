<template>
  <main class="kb-main" id="kb-mypage-account">
    <lxp-main-header :show-back="true" />
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- mypage-header -->
      <div class="mypage-header">
        <!-- mypage-my -->
        <div class="mypage-my">
          <div class="my-avatar">
            <div class="image">
              <img :src="profileImg"  @error="setDefaultImg"/> <!--   getProfileImg(session.lrnerId) -->
            </div>
           <button class="kb-btn-edit" @click="showMyPortraitModal">
             <span class="badge"><em class="badge-text">변경</em></span>
           </button>
          </div>
          <div class="my-title"><h3 class="title">내 정보 수정</h3></div>
        </div>
        <!-- //mypage-my -->
      </div>
      <!-- //mypage-header -->
      <div class="mypage-body">
        <div class="">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:210px;" />
                <col style="width:auto;" />
                <col style="width:300px;" />
              </colgroup>
              <tbody v-if="isLoading">
              <tr>
                <th><span class="th-title">이름</span></th>
                <td colspan="2"><span class="td-text">{{ session.lrnerNm }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">직원번호</span></th>
                <td colspan="2"><span class="td-text">{{ session.lrnerId }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">직급/직위</span></th>
                <td colspan="2"><span class="td-text">{{ session.jbgdNm }}/{{ session.jbpsNm }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">소속부점</span></th>
                <td colspan="2"><span class="td-text">{{ session.deptNm }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">담당직무</span></th>
                <td colspan="2"><span class="td-text">{{ params.fhnjikmu }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">나의 CDP</span></th>
                <td colspan="2"><span class="td-text">[직군] {{ params.jobGrpNm }}, [직렬] {{ params.jobFncNm }}, [직무] {{ params.fhnjikmu }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">E-mail</span></th>
                <td colspan="2"><span class="td-text">{{ params.finemail }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">자택전화번호</span></th>
                <td colspan="2"><span class="td-text">{{ params.hoTelno }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">휴대폰전화번호</span></th>
                <td colspan="2"><span class="td-text">{{ params.hpTelno }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">자택주소</span></th>
                <td colspan="2"><span class="td-text">({{ params.hoUpbho }}) {{ params.hoAddrRange }}</span></td>
              </tr>
              <tr>
                <th><span class="th-title">우편물수령주소</span></th>
                <td><span class="td-text">{{params.address}}</span></td>
                <td class="text-end"><a class="td-link" @click="changeAddr"><strong class="td-text">우편물 수령주소 변경<i class="icon-arrow"></i></strong></a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="kb-table-bottom">
            <p class="text text-muted">자택주소, 전화번호 정보는 인사시스템(MyHR)에서 직접 변경하시기 바라며, 변경 시 익일 반영됩니다.</p>
            <p class="text text-muted">(경로 : MyHR 시스템 접속 > 직원정보 > 인사기록카드 > 내프로필 > 인적사항)</p>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
    <MyPortraitModal />
    <MyCommonAddress v-model="addrModal" :user-addr-info="params" />
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import MyPortraitModal from '@/components/mypage/MyPortraitModal.vue';
import MyCommonAddress from "@/components/mypage/MyCommonAddress";
import {profileSetup} from "@/assets/js/modules/mypage/profile/profile-setup";

export default {
  name: 'MyProfile',
  components: {MyCommonAddress, MyPortraitModal, LxpMainHeader},
  setup: profileSetup
};
</script>